<template>
  <l-map
    :center="center"
    :zoom="zoomValue"
    class="map"
    ref="map"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
  >
    <!-- <l-control-fullscreen position="topleft"
      :options="{ title: { 'false': 'Go big!', 'true': 'Be regular' } }"
  ></l-control-fullscreen> -->

    <l-tile-layer
      :url="url"
    >
    </l-tile-layer>
    <enseigne
      v-for="marker in markers"
      :key="marker.id"
      :marker="marker"
    >
    </enseigne>
      <!-- <l-polygon  :lat-lngs="polygon.latlngs"  :color="polygon.color">
        </l-polygon> -->
    <div>
      <template  v-for="centrale in centralesFiltered" >
      <div v-if="centrale.polygon.length > 1 " :key="centrale.id">
        <template v-for="(poly, index) in centrale.polygon">
              <l-polygon :name="centrale.name" :fill-opacity="0.5" :lat-lngs="poly.polygon.latlngs" :key="index" :color="poly.polygon.color"  :fill-color="poly.polygon.fillColor">

              </l-polygon>
        </template>
        </div>
        <div v-else :key="centrale.id">
          <l-polygon :name="centrale.name" :fill-opacity="0.5" :lat-lngs="centrale.polygon.latlngs" :key="centrale.id" :color="centrale.polygon.color"  :fill-color="centrale.polygon.fillColor">
          </l-polygon>
        </div>
      </template>
    </div>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LPolygon } from 'vue2-leaflet';
import { mapState } from 'vuex'
import Enseigne from './Enseigne'
import coords from '../assets/coords.json'
import centrales from '../assets/centrales.json'
import 'leaflet/dist/leaflet.css';

export default {
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    Enseigne
  },
  data () {
    return {
      // url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      url: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      zoomValue: 7,
    }
  },
    computed: {
    ...mapState({
      center: state => state.enseignes.center,
      enseignes: state => state.enseignes.all,
      centrales: state => state.enseignes.centrales,
      centraleType: state => state.enseignes.centraleType,
      markers: state => state.enseignes.coords,
      selectedEnseigne: state => state.enseignes.current,
      zoom: state => state.enseignes.zoom,
      centraleLeclerc: state => state.enseignes.centraleLeclerc,
    }),
    centralesFiltered() {
      return this.centrales.filter(type => type.centraleType === this.centraleType)
    }
  },
  watch: {
    zoom(){
      if (this.selectedEnseigne.latitude) {
        this.$store.dispatch('setCenter',[Number(this.selectedEnseigne.latitude), Number(this.selectedEnseigne.longitude)])
        this.$store.dispatch('setZoom',true)
        this.zoomValue = 12
      }
    }
  },
  created() {
    if (this.enseignes.length === 0)
    this.$store.dispatch('setEnseignes', coords)
    this.$store.dispatch('setCentrales', centrales)
  },
  methods: {
    zoomUpdated () {
      if (this.zoom === true) {
        this.$store.dispatch('setZoom', false)
      }
    },
    centerUpdated () {
      if (this.zoom === true) {
      this.$store.dispatch('setZoom', false)
      }
    }
  },
}
</script>

<style>
  .map {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow :hidden
  }
</style>
